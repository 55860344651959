import { Link } from "gatsby";
import React from "react";
import { css } from "@emotion/react";
import AdNerfLogoImage from "../../../src/content/img/adnerf-logo.svg";
import AdNerfLogoWhiteImage from "../../../src/content/img/adnerf-logo-white.svg";

import config from "../../website-config";

export const AdNerfLogo = () => (
  <img src={AdNerfLogoImage} alt={config.title} />
);

export const AdNerfLogoWhite = () => (
  <img src={AdNerfLogoWhiteImage} alt={config.title} />
);

export const SiteNavLogo = () => (
  <Link className="site-nav-logo" css={SiteNavLogoStyles} to="/">
    <AdNerfLogo />
  </Link>
);

export const SiteNavLogoWhite = () => (
  <Link className="site-nav-logo" css={SiteNavLogoStyles} to="/">
    <AdNerfLogoWhite />
  </Link>
);

const SiteNavLogoStyles = css`
  position: relative;
  z-index: 100;
  flex-shrink: 0;
  display: inline-block;
  margin-right: 32px;
  color: #fff;
  font-size: 1.7rem;
  line-height: 1.8rem;
  font-weight: bold;
  letter-spacing: -0.5px;
  text-transform: none;

  :hover {
    text-decoration: none;
  }

  img {
    display: block;
    width: auto;
    height: 50px;
  }
`;

import React from 'react';
import styled from '@emotion/styled';
import { css } from "@emotion/react";

interface WrapperProps {
  className?: string;
}

const PageTemplate = css`
  .site-main {
    margin-top: 64px;
    padding-bottom: 4vw;
    color: #000;
  }
`;

export const Wrapper: React.FC<WrapperProps> = ({ children, className }) => (
  <StyledWrapper className={className} css={PageTemplate}>{children}</StyledWrapper>
);

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

